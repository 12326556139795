const peopleRange = [
  { text: "全部", minCount: "", maxCount: "" },
  { text: "10人以下", minCount: 0, maxCount: 10 },
  { text: "10-30人", minCount: 10, maxCount: 30 },
  { text: "30-60人", minCount: 30, maxCount: 60 },
  { text: "60-100人", minCount: 60, maxCount: 100 },
  { text: "100-200人", minCount: 100, maxCount: 200 },
  { text: "200以人", minCount: 200, maxCount: "" },
];
const appointmentTypeList =[
  {text:'个人预约'},
  {text:'团队预约'}
]
const appointStatus = [
  { key: 1, value: "待付款" },
  { key: 2, value: "已完成" },
  { key: 3, value: "已关闭" },
];
export { peopleRange, appointStatus, appointmentTypeList };