<template>
  <div :class="['areaAppoint', { isSwitchOld }]">
    <div class="switch" @click="handelChangeSwitch">
      <img src="./img/switch.png" alt="" />
      <span v-if="!isSwitchOld">切换老年版</span>
      <span v-else>切换标准版</span>
    </div>
    <div class="areaAppoint-t">
      <div class="tips">
        <div class="tips-l"><img src="./img/sound-fill.png" alt="" /></div>
        <div class="tips-r">如遇法定节假日，请先与管理员确认场地是否开放</div>
      </div>
      <div class="search">
        <div class="search-l" @click="showDate">
          <div class="item-l">{{ requestData.date }}</div>
          <div class="item-r">
            <img
              :class="['arrow', { downArrow: show }]"
              src="./img/downarrow.png"
              alt=""
            />
          </div>
        </div>
        <div class="search-c" @click="showType">
          <div class="item-l">{{ requestData.type }}</div>
          <div class="item-r">
            <img
              :class="['arrow', { downArrow: pickShow }]"
              src="./img/downarrow.png"
              alt=""
            />
          </div>
        </div>
        <div class="search-r" @click="showPeople">
          <div class="item-l">{{ requestData.num }}</div>
          <div class="item-r">
            <img
              :class="['arrow', { downArrow: peopleShow }]"
              src="./img/downarrow.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="oList">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          v-for="(item, index) in listData"
          :key="index"
          class="areaAppointItem"
          @click="toDetail(item)"
        >
          <div class="areaAppointItem-t">
            <div class="areaAppointItem-tl">
              <img :src="item.picUrl" alt="" />
            </div>
            <div class="areaAppointItem-tr">
              <div class="name">{{ item.name }}</div>
              <div class="num">
                <div class="num-l">{{ item.area }}㎡</div>
                |
                <div class="num-r">{{ item.count }}人</div>
              </div>
              <div class="type">会议室</div>
            </div>
          </div>
          <div class="areaAppointItem-b">
            <div class="areaAppointItem-bl"></div>
            <div class="areaAppointItem-br">立即预定</div>
          </div>
        </div>
      </v-list>
    </div>
    <v-picker
      :columns="columns"
      :valueShow="pickShow"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
    <v-picker
      :columns="peopleColumns"
      :valueShow="peopleShow"
      :valueKey="peopleKey"
      @clickOverlay="clickPeopleOverlay"
      @cancel="cancelPeoplePick"
      @confirmPicker="confirmPeople"
    ></v-picker>
    <v-dateTimePicker
      :valueShow="show"
      v-model="valueDate"
      @clickOverlay="clickOverlay"
      @cancel="cancel"
      @confirmPicker="confirmPicker"
    ></v-dateTimePicker>
  </div>
</template>

<script>
import { getAreaListUrl, getAreaTypeUrl } from "./api.js";
import { peopleRange } from "./map.js";
import { gloabalCount } from "@/utils/common";
import { handleImg } from "@/utils/utils";
var moment = require("moment");
export default {
  name: "areaAppoint",
  data() {
    return {
      finishedText: "没有更多了",
      peopleKey: "text",
      valueKey: "typeName",
      columns: [{ typeName: "全部", id: "" }],
      peopleColumns: peopleRange,
      pickShow: false,
      valueDate: new Date(),
      show: false,
      peopleShow: false,
      finished: false,
      loading: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "全部",
        typeId: "",
        num: "全部",
        minCount: "",
        maxCount: "",
      },
      listData: [],
      isSwitchOld: false,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
  },
  created() {
    if (localStorage.getItem("isSwitchOld") != null) {
      this.isSwitchOld = JSON.parse(localStorage.getItem("isSwitchOld"));
    }
  },
  mounted() {
    // this.getActivityList();
    this.getAreaTypeList();
    gloabalCount("", 5, 1);
  },
  methods: {
    //切换
    handelChangeSwitch() {
      this.isSwitchOld = !this.isSwitchOld;
      localStorage.setItem("isSwitchOld", this.isSwitchOld);
    },
    showPeople() {
      this.peopleShow = true;
    },
    cancelPeoplePick() {
      this.peopleShow = false;
    },
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.$refs.list._data.loading = true;
      this.finished = false;
      this.getActivityList();
    },
    confirmPick(value) {
      this.pickShow = false;
      this.requestData.type = value.typeName;
      this.requestData.typeId = value.id;
      this.init();
    },
    cancelPick() {
      this.pickShow = false;
    },
    clickPeopleOverlay() {
      this.peopleShow = false;
    },
    clickPickOverlay() {
      this.pickShow = false;
    },
    confirmPeople(value) {
      console.log(value);
      this.peopleShow = false;
      this.requestData.num = value.text;
      this.requestData.minCount = value.minCount;
      this.requestData.maxCount = value.maxCount;
      this.init();
    },
    confirmPicker(value) {
      this.show = false;
      this.requestData.date = moment(value).format("YYYY-MM-DD");
      this.init();
    },
    cancel() {
      this.show = false;
    },
    clickOverlay() {
      this.show = false;
    },
    showType() {
      this.pickShow = true;
    },
    showDate() {
      this.show = true;
    },
    onLoad() {
      this.getActivityList();
    },
    getAreaTypeList() {
      this.$axios.get(`${getAreaTypeUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            this.columns.push(item);
          });
        } else {
        }
      });
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        date: this.requestData.date,
        minCount: this.requestData.minCount,
        maxCount: this.requestData.maxCount,
        typeId: this.requestData.typeId,
      };
      this.$axios.get(`${getAreaListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.picUrl && item.picUrl.length > 0) {
              item.picUrl = handleImg(400, 400, item.picUrl);
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "areaDetail",
        query: { placeId: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.areaAppoint {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f7f7f7;
  &.isSwitchOld {
    .switch {
      span {
        font-size: 42px;
      }
    }
    .areaAppoint-t {
      .tips {
        font-size: 42px;
        .tips-l {
          width: 64px;
          height: 64px;
        }
        .tips-r {
          font-size: 42px;
        }
      }
      .search {
        .search-l {
          .item-l {
            font-size: 46px;
          }
          .item-r {
            width: 32px;
            height: 32px;
          }
        }
        .search-c {
          .item-l {
            font-size: 46px;
          }
          .item-r {
            width: 32px;
            height: 32px;
          }
        }
        .search-r {
          .item-l {
            font-size: 46px;
          }
          .item-r {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    .oList {
      .areaAppointItem {
        .areaAppointItem-t {
          .areaAppointItem-tr {
            font-size: 44px;
            .name {
              font-size: 46px;
            }
            .num {
              font-size: 38px;
            }
            .type {
              font-size: 38px;
            }
          }
        }
        .areaAppointItem-b {
          font-size: 42px;
          .areaAppointItem-br {
            width: 190px;
            height: 78px;
            line-height: 78px;
            font-size: 42px;
            border-radius: 48px;
          }
        }
      }
    }
  }
  .switch {
    padding: 20px 32px 0 0;
    box-sizing: border-box;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      margin-right: 10px;
      width: 34px;
      height: 34px;
    }
    span {
      font-size: 28px;
    }
  }
  .downArrow {
    transform: rotate(180deg);
  }
  .areaAppoint-t {
    background: #fff;
  }
  .tips {
    font-size: 28px;
    display: flex;
    padding: 20px 0 20px 32px;
    background: rgba(254, 111, 22, 0.1);
    align-items: center;
    .tips-l {
      width: 32px;
      height: 32px;
      margin-right: 4px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tips-r {
      width: 616px;
      // height: 40px;
      font-size: 24px;
      font-weight: 400;
      color: #fe6f16;
      line-height: 44px;
    }
  }
  .search {
    display: flex;
    justify-content: space-between;
    padding: 26px 32px;
  }
  .search-l {
    display: flex;
    align-items: center;
    .item-l {
      font-size: 32px;
      color: #323334;
      line-height: 44px;
    }
    .item-r {
      width: 24px;
      height: 24px;
      font-size: 0;
      margin-left: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .search-c {
    display: flex;
    align-items: center;
    .item-l {
      font-size: 32px;
      color: #323334;
      line-height: 44px;
    }
    .item-r {
      width: 24px;
      height: 24px;
      font-size: 0;
      margin-left: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .search-r {
    display: flex;
    align-items: center;
    .item-l {
      font-size: 32px;
      color: #323334;
      line-height: 44px;
    }
    .item-r {
      width: 24px;
      height: 24px;
      font-size: 0;
      margin-left: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .oList {
    padding: 32px;
  }
  .areaAppointItem {
    position: relative;
    margin-bottom: 32px;
    height: 224px;
    background: #ffffff;
    border-radius: 16px;
    .areaAppointItem-t {
      display: flex;
      .areaAppointItem-tl {
        flex-shrink: 0;
        width: 224px;
        height: 224px;
        border-radius: 16px 0 0 16px;
        margin-right: 16px;
        img {
          border-radius: 16px 0 0 16px;
          width: 100%;
          height: 100%;
        }
      }
      .areaAppointItem-tr {
        max-width: calc(100% - 240px);
        box-sizing: border-box;
        flex: 1;
        font-size: 30px;
        padding-top: 24px;
        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
        }
        .num {
          display: flex;
          font-size: 24px;
          font-weight: 400;
          color: #646668;
          line-height: 36px;
          margin: 16px 0 30px;
          .num-l {
            margin-right: 10px;
          }
          .num-r {
            margin-left: 10px;
          }
        }
        .type {
          font-size: 24px;
          font-weight: 400;
          color: #646668;
          line-height: 36px;
        }
      }
    }
    .areaAppointItem-b {
      /*align-items: center;*/
      position: absolute;
      right: 32px;
      bottom: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 40px;
      font-size: 28px;

      .areaAppointItem-bl {
        color: sandybrown;
      }
      .areaAppointItem-br {
        color: #fff;
        text-align: center;
        font-size: 28px;
        line-height: 64px;
        width: 176px;
        height: 64px;
        background: #007eff;
        border-radius: 32px;
      }
    }
  }
}
</style>
